import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const CONTACTS_LOADING = 'NEWS_LOADING'

export const setLoadingContactsAction = (loading) => (dispatch,) => dispatch({
	type: CONTACTS_LOADING,
	loading,
},)

export const contactsCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingContactsAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingContactsAction(false))
		return data.contact.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingContactsAction(false))
		return null
	}
}

export const contactsUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingContactsAction(true))
		const { data, } = await action({ variables, })
		return data.contact
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingContactsAction(false))
	}
	dispatch(setLoadingContactsAction(false))
}
