import React, { Suspense, } from 'react'
import ReactDOM from 'react-dom'
import { Provider, } from 'react-redux'
import { DndProvider, } from 'react-dnd'
import { HTML5Backend, } from 'react-dnd-html5-backend'
import * as serviceWorker from './serviceWorker'
import { configureStore, } from './redux/store'
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));

const Main = () => {
	return (
		<Provider store={configureStore()}>
			<Suspense fallback={<div className="loading" />}>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</Suspense>
		</Provider>
	);
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
