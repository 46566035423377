import { combineReducers, } from 'redux'
import auth from './auth/reducer'
import menu from './menu/reducer'
import film from './film/reducer'
import person from './person/reducer'
import news from './news/reducer'
import vacancy from './vacancy/reducer'
import rewards from './rewards/reducer'
import users from './users/reducer'
import contact from './contact/reducer'
import digest from './digest/reducer'
import events from './events/reducer'

const reducers = combineReducers({
	auth,
	menu,
	film,
	person,
	news,
	vacancy,
	rewards,
	users,
	contact,
	digest,
	events,
})

export default reducers
