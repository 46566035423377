export const portLink = `${process.env.NODE_ENV === 'development'
	? process.env.REACT_APP_HOST_URL : `https://${document.domain}`}`

export const requestGraphQLLink = process.env.REACT_APP_GRAPHQL_REQUEST_PATH
export const requestRestLink = process.env.REACT_APP_REST_REQUEST_PATH

export const promoLink = (process.env.NODE_ENV === 'development'
	? `${process.env.REACT_APP_PROMO_SSR_HOST_PROTOCOL}://${process.env.REACT_APP_PROMO_HOST_URL}/` : `${window.location.protocol}//${document.domain}/api/`)

export const promoPort = process.env.NODE_ENV === 'development' ? 8280 : 8280

export const isPromo = process.env.REACT_APP_NODE_CONTENT === 'promo'
