import NotificationManager from '../../components/common/react-notifications/NotificationManager'
import { getLoginQuery } from '../../api/rest'
import storageHelper from '../../helpers/storageHelper'
import clientGQL from '../../api/graphql'
import { GET_CURRENT_USER_API } from '../../api/users/usersQuery'
import { setLoadingAuthAction, updateAuthAction } from '../auth/actions'
import { GET_FORM_SUBSCRIBE_API } from '../../api/forms/formsQuery'
import moment from 'moment'

export const USERS_LOADING = 'USERS_LOADING'

export const setLoadingUsersAction = (loading) => (dispatch,) => dispatch({
	type: USERS_LOADING,
	loading,
},)

export const usersCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUsersAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingUsersAction(false))
		NotificationManager.success(
			'Пароль отправлен на email',
			'Пользователь создан!',
			3000,
			null,
			null,
		)
		return data.user.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingUsersAction(false))
		return null
	}
}

export const usersUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingUsersAction(true))
		const { data, } = await action({ variables, })
		return data.user
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingUsersAction(false))
	}
	dispatch(setLoadingUsersAction(false))
}

export const downloadSubscribersAction = () => async (dispatch,) => {
	try {
		const { data, } = await clientGQL.query({
			query: GET_FORM_SUBSCRIBE_API,
			variables: {
				size: 9000,
				from: 0,
			},
			notifyOnNetworkStatusChange: true,
			fetchPolicy: 'network-only',
		})
		const rows = [
			['ID', 'Email', 'Created at']
		];
		(data.forms?.items || []).sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).forEach(({
			id,
			email,
			createdAt,
		}) => rows.push([id, email, moment(createdAt).format('DD.MM.YYYY HH:mm')]))

		let csvContent = "data:text/csv;charset=utf-8,"
			+ rows.map(e => e.join(",")).join("\n");
		const encodedUri = encodeURI(csvContent);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", `subscribers_${moment(new Date()).format('DD-MM-YYYY_HH:mm')}.csv`);
		link.click();

	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема при скачивании',
			3000,
			null,
			null,
		)
		dispatch(setLoadingAuthAction(false))
	}
	dispatch(setLoadingAuthAction(false))
}
