/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = 'en'
export const localeOptions = [
	{ id: 'en', name: 'English', direction: 'ltr', },
	{ id: 'es', name: 'Español', direction: 'ltr', },
]

export const searchPath = '/pages/search'

/*
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const defaultColor = 'light.purple'
export const defaultDirection = 'ltr'
export const themeColorStorageKey = '__theme_color'
export const themeRadiusStorageKey = '__theme_radius'
