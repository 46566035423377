import { gql, } from 'apollo-boost'

export const GET_FORM_MOVIE_REQUEST_API = gql`
    query getRequestMaterialSearchApi(
        $from: Int!,
        $size: Int!,
        $createdBy: Int,
        $managedStatus: MovieSubscribeManagedStatus,
        $movieId: Int,
    ) {
        forms: requestMaterialSearch(
            input: {
                createdBy: $createdBy,
                managedStatus: $managedStatus,
                movieId: $movieId,
            },
            pagination: {from: $from, size: $size},
            sort: {direct: DESC,  field: CREATED_AT}
        ) {
            list {
                id
                additionalMovies {
                    id
                    name
                }
                movie {
                    id
                    name
                }
                name
                surname
                organization
                email
                createdAt
                comment
                createdBy {
                    createdAt
                    deletedAt
                    email
                    id
                    name
                    role
                    state
                    updatedAt
                }
                link
                managedAt
                managedBy {
                    createdAt
                    deletedAt
                    email
                    id
                    name
                    role
                    state
                    updatedAt
                }
                managedStatus
            }
            pagination {
                from
                size
                total
            }
        }
    }
`

export const GET_FORM_SUBSCRIBE_API = gql`
	query subscribeSearch($from: Int!, $size: Int!) {
		forms: subscribeSearch(pagination: {from: $from, size: $size}) {
			items {
				id
				email
				createdAt
			}
			pagination {
				from
				size
				total
			}
		}
	}
`

export const APPROVE_REQUEST_API = gql`
	mutation approveUserRegistrationApi($comment: String, $link: String!, $id: ID!) {
		approveRequestMaterial(comment: $comment, id: $id, link: $link)
	}
`
export const REJECT_REQUEST_API = gql`
	mutation rejectRequestMaterialApi($id: ID!) {
		rejectRequestMaterial(id: $id)
	}
`
export const GET_FORM_RESUME_API = gql`
	query getResumeList($from: Int!, $size: Int!) {
		forms: resumeList(pagination: {from: $from, size: $size}) {
			items {
				id
				name
				surname
				email
				phone
				resume
				message
				createdAt
			}
			pagination {
				from
				size
				total
			}
		}
	}
`
export const GET_FORM_WRITERS_API = gql`
	query forWritersFormSearch($from: Int!, $size: Int!) {
		forms: forWritersFormSearch(pagination: {from: $from, size: $size}) {
			items {
				id
				movieName
				type
				stage
				shortDescription
				sinopsis
				firstName
				lastName
				middleName
				phone
				email
				createdAt
				lang
				createdIp
			}
			pagination {
				from
				size
				total
			}
		}
	}
`
