import { ApolloClient, } from 'apollo-client'
import { InMemoryCache, } from 'apollo-cache-inmemory'
import { setContext, } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { promoLink, } from '../utilits/envUtilit'
import storageHelper from '../helpers/storageHelper'

const authLink = setContext((_, { headers, },) => {
	const token = storageHelper.get('__token__', 'cookie',) || ''
	return {
		headers: {
			...headers,
			'x-auth': token,
		},
	}
},)

const link = createUploadLink({
	uri: `${promoLink}query`,
},)

const onErrorLink = onError(({
	graphQLErrors,
	networkError,
	response,
	operation,
	forward
}) => {
	if (graphQLErrors) {
		graphQLErrors.map(({
			message,
			locations,
			path
		}) =>
			console.log(
				`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			),
		)
	}

	if (networkError) {
		if (networkError.statusCode === 403) {
			storageHelper.remove('__token__', 'cookie',)
			window.location.pathname = '/'
		}
		console.log(`[Network error]: ${networkError}`)
	}
})

const clientGQL = new ApolloClient({
	link: authLink.concat(onErrorLink,)
		.concat(link,),
	cache: new InMemoryCache({
		addTypename: false
	}),
},)

export default clientGQL
