import NotificationManager from '../../components/common/react-notifications/NotificationManager'
import { setLoadingContactsAction } from '../contact/actions'

export const DIGEST_LOADING = 'DIGEST_LOADING'

export const setLoadingDigestAction = (loading) => (dispatch,) => dispatch({
	type: DIGEST_LOADING,
	loading,
},)

export const digestCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingDigestAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingDigestAction(false))
		return data.content.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingDigestAction(false))
		return null
	}
}

export const digestUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingDigestAction(true))
		const { data, } = await action({ variables, })
		return data.content.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingDigestAction(false))
	}
	dispatch(setLoadingDigestAction(false))
}
