import NotificationManager from '../../components/common/react-notifications/NotificationManager'

export const EVENTS_LOADING = 'EVENTS_LOADING'

export const setLoadingEventAction = (loading) => (dispatch,) => dispatch({
	type: EVENTS_LOADING,
	loading,
},)

export const eventCreateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingEventAction(true))
		const { data, } = await action({ variables, })
		dispatch(setLoadingEventAction(false))
		return data.content.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема создания',
			3000,
			null,
			null,
		)
		dispatch(setLoadingEventAction(false))
		return null
	}
}

export const eventUpdateAction = (action, variables,) => async (dispatch,) => {
	try {
		dispatch(setLoadingEventAction(true))
		const { data, } = await action({ variables, })
		return data.content.id
	} catch (e) {
		NotificationManager.error(
			e.message || '',
			'Проблема Обновления',
			3000,
			null,
			null,
		)
		dispatch(setLoadingEventAction(false))
	}
	dispatch(setLoadingEventAction(false))
}
